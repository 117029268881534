import React from "react";
import { isMobile } from "react-device-detect";

import "./App.scss";
import BackgroundElement from "./components/BackgroundElement";
import Section from "./components/Section";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0, language: "et" };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  setLangauge = () => {
    this.setState(
      (prevState) => ({
        language: prevState.language === "et" ? "ru" : "et",
      }),
      () => console.log(this.state.language)
    );
  };
  render() {
    const { language } = this.state;
    const isMobileWithWidth = isMobile || this.state.width < 768 ? true : false;

    return (
      <div className={isMobileWithWidth ? "isMobile" : ""}>
        <header>
          <BackgroundElement image="./images/asset_01.png" location="before" />
          <button className="languagePicker" onClick={() => this.setLangauge()}><span>
            {language === "et" ? "ru" : "et"}</span>
          </button>
          <img
            src={
              language === "et"
                ? isMobileWithWidth
                  ? "./images/header_mob_est.jpg"
                  : "./images/header_01.png"
                : isMobileWithWidth
                ? "./images/header_mob_rus.jpg"
                : "./images/header_01_rus.png"
            }
            alt=""
          />
          <BackgroundElement image="./images/asset_03.png" location="after" />
        </header>

        <Section className="first" bgColor="secondary" columns={2}>
          {" "}
          <BackgroundElement image="./images/asset_04.png" location="before" />
          <h2>
            {language === "et"
              ? "5 PÕHJUST, MIKS EELISTADA TÄISTERAST PAGARITOOTEID"
              : "5 ПРИЧИН, ЧТОБЫ ПРЕДПОЧИТАТЬ ЦЕЛЬНОЗЕРНОВУЮ ВЫПЕЧКУ "}
          </h2>
          <ol>
            <li>
              {language === "et"
                ? "Täisterast valmistatud leivad-saiad on olulised kiudainete allikad."
                : "Хлеб и булка из цельнозерновой муки – важные источники клетчатки. "}
            </li>
            <li>
              {language === "et"
                ? "Täisteraviljas on vajalikke B-rühma vitamiine."
                : "Цельнозерновые продукты содержат витамины группы В. "}
            </li>
            <li>
              {language === "et"
                ? "Täisteras sisalduvad organismile vajalikud mineraalained magneesium, tsink, fosfor, vask ja raud."
                : "Цельнозерновая мука содержит необходимые для организма минералы – магний, цинк, фосфор, медь и железо."}
            </li>
            <li>
              {language === "et"
                ? "Täistera pagaritooted on rikkalikud taimse valgu allikad."
                : "Цельнозерновая выпечка – богатый источник растительных белков."}
            </li>
            <li>
              {language === "et"
                ? "Täisteras sisalduvad nii vees lahustuvad kui ka mittelahustuvad kiudaineid, mis aitavad kõhtu täita ja täiskõhutundel kaua püsida."
                : "Растворимая и нерастворимая клетчатка, которая содержится в цельнозерновых продуктах, помогает надолго насытиться."}
            </li>
          </ol>
          <BackgroundElement image="./images/asset_06.png" location="after" />
        </Section>
        <section className="image">
          <img
            src="./images/1.jpg"
            alt=""
            style={{ transform: "translateY(-40%)" }}
          />
        </section>
        <Section
          className="second"
          bgColor="primary"
          columns={1}
          flexDirection="column"
        >
          <BackgroundElement image="./images/asset_09.png" location="before" />
          <h2 className="text-center">
            {language === "et"
              ? "KUIDAS TÄISTERATOODE ÄRA TUNDA?"
              : "КАК УЗНАТЬ ЦЕЛЬНОЗЕРНОВОЙ ПРОДУКТ?"}
          </h2>
          <div className="row smaller">
            <img
              className="round-icon"
              src="./images/round-icon-1.svg"
              alt=""
            />
            <div className="content">
              <h3>
                {language === "et"
                  ? "Otsi täistera märki!"
                  : "Ищите цельнозерновой знак!"}{" "}
              </h3>
              <p>
                {language === "et"
                  ? "Eestis kehtib täistera mõiste kasutamise hea tava, mis sõnastab, millist toodet võib nimetada täisteratooteks."
                  : "В Эстонии действует добрый обычай по использованию понятия «цельнозерновой», который определяет, какой продукт можно называть цельнозерновым."}
              </p>{" "}
            </div>{" "}
          </div>

          <div className="row smaller">
            <img
              className="round-icon"
              src={
                language === "et"
                  ? "./images/round-icon-2.svg"
                  : "./images/round-icon-2-rus.svg"
              }
              alt=""
            />{" "}
            <div className="content">
              <h3>
                {language === "et"
                  ? "Jälgi toote koostist!"
                  : "Изучите состав продукта!"}
              </h3>
              <p>
                {language === "et"
                  ? "Pagaritoodetes peaks kasutatavast teravilja koostisosadest olema 50% täisteravili."
                  : "Не менее половины злаков, использованных в выпечке, должны быть цельнозерновыми. "}
              </p>
            </div>
          </div>
          <div className="row smaller">
            <img
              className="round-icon"
              src={
                language === "et"
                  ? "./images/round-icon-3.svg"
                  : "./images/round-icon-3-rus.svg"
              }
              alt=""
            />{" "}
            <div className="content">
              <h3>
                {language === "et"
                  ? "Vaata kiudainesisaldust!"
                  : "Проверьте содержание клетчатки!"}
              </h3>
              <p>
                {language === "et"
                  ? "Kõrgem kiudainesisaldus (vähemalt 6 g kiudainet 100 g kohta) on märk suuremast täisterasisaldusest."
                  : "Высокое содержание клетчатки (не менее 6 г клетчатки на 100 г продукта) свидетельствует о большем содержании цельнозерновых ингредиентов."}
              </p>
            </div>
          </div>
          <BackgroundElement image="./images/asset_11.png" location="after" />
        </Section>
        <section className="image">
          <img
            src="./images/2.jpg"
            alt=""
            style={{ transform: "translateY(-55%)" }}
          />
        </section>

        <Section className="third" bgColor="primary" columns={3}>
          <BackgroundElement image="./images/asset_14.png" location="before" />
          <h2 className="text-center">
            {language === "et"
              ? " Täisteratooted sisaldavad nii vees lahustuvaid kui ka mittelahustuvaid kiudaineid, mis"
              : "Цельнозерновые продукты содержат и растворимую, и нерастворимую клетчатку, которая "}
          </h2>
          <div className="column">
            <img className="icon" src="./images/Line-icon-1.svg" alt="" />
            <div className="column-text">
              <h3>
                {language === "et" ? "võivad avaldada" : "может оказывать"}
              </h3>
              <ul>
                <li>
                  {language === "et"
                    ? "kasulikku mõju seedimisele"
                    : "положительное влияние на пищеварение"}
                </li>
                <li>
                  {language === "et"
                    ? "kasulikku mõju vere suhkru-ja insuliinisisaldusele"
                    : "положательное влияние на содержание сахара и инсулина в крови"}
                </li>
              </ul>
            </div>{" "}
          </div>
          <div className="column">
            <img className="icon" src="./images/Line-icon-2.svg" alt="" />
            <div className="column-text">
              <h3>
                {language === "et" ? "võivad vähendada" : "может снизить"}
              </h3>
              <ul>
                <li>
                  {language === "et"
                    ? "kolesteroolitaset veres"
                    : "уровень холестерина в крови"}
                </li>
                <li>
                  {language === "et"
                    ? "glükoositaseme tõusu veres pärast sööki"
                    : "повышение уровня глюкозы в крови после еды"}
                </li>
                <li>
                  {language === "et" ? "kõhukinnisust" : "количество запоров"}
                </li>
              </ul>{" "}
            </div>
          </div>
          <div className="column">
            <img className="icon" src="./images/Line-icon-3.svg" alt="" />
            <div className="column-text">
              <h3>{language === "et" ? "võivad aidata" : "может помочь"}</h3>
              <ul>
                <li>
                  {language === "et"
                    ? "kontrollida kehakaalu"
                    : "контролировать свой вес"}
                </li>
                <li>
                  {language === "et"
                    ? "vältida näljatunnet"
                    : "избегать чувства голода"}
                </li>
                <li>
                  {language === "et"
                    ? "täita hästi kõhtu"
                    : "как следует насытиться"}
                </li>
              </ul>
            </div>
          </div>
          <BackgroundElement image="./images/asset_16.png" location="after" />
        </Section>

        <section className="image">
          <img
            src="./images/3.jpg"
            alt=""
            style={{ transform: "translateY(-60%)" }}
          />
        </section>

        <Section className="forth" bgColor="secondary" columns={2}>
          <BackgroundElement image="./images/asset_19.png" location="before" />
          <div className="column">
            <h2>
              {language === "et"
                ? "TÄISTERA POLE AINULT RUKIS"
                : "«ЦЕЛЬНОЗЕРНОВОЙ БЫВАЕТ НЕ ТОЛЬКО РОЖЬ»"}
            </h2>
            <p>
              {language === "et"
                ? "Pane tähele, et täisteratooteid ei valmistata ainult rukkiteradest. Täisteratoodete laiast valikust leiad ka kaera, nisu, odra ja tatra jahvatatud, purustatud ning helvestatud teradega tooteid."
                : "Обратите внимание, что цельнозерновые продукты готовятся не только из ржаной муки. В широком выборе цельнозерновых продуктов вы также  найдете продукты из хлопьев и муки других злаков – овса, пшеницы, ячменя, гречки. "}
            </p>
          </div>
          <div className="column">
            <h2>
              {language === "et"
                ? "Täisterast pagaritoode on täisväärtuslik eine!"
                : "Цельнозерновая выпечка – полноценная еда!"}
            </h2>
            <p>
              {language === "et"
                ? "Eesti Toiduliit, Tervise Arengu Instituut ja Eesti toitumissoovitused soovitavad eelistada täisterast pagaritooteid."
                : "Союз предприятий пищевой промышленности , Институт развития здоровья и Эстонские государственные рекомендации по питанию рекомендуют предпочитать цельнозерновую выпечку. "}
            </p>
          </div>{" "}
          <BackgroundElement image="./images/asset_21.png" location="after" />
        </Section>
        <Section className="footer" bgColor="primary" columns={2}>
          <a href="https://toiduliit.ee/" target="_blank" rel="noreferrer">
            <img
              className="logo"
              src="./images/toiduliit.png"
              alt="Toiduliit"
            />
          </a>
          <p>Turuarendustoetuse abiga rahastatud tegevus</p>
        </Section>
      </div>
    );
  }
}
