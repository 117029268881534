import React, { Component } from "react";

export default class Section extends Component {
  render() {
    const { bgColor, columns, flexDirection, className } = this.props;
    return (
      <section
        className={`${bgColor ? "section-" + bgColor : ""} ${
          columns ? "columns-" + columns : ""
        } ${flexDirection ? "section-"+flexDirection : ""} ${className ? className : ""}        
        `}
      >
        <div className="section-inside">{this.props.children}</div>
      </section>
    );
  }
}
