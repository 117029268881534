import React, { Component } from "react";

export default class BackgroundElement extends Component {
  render() {
    const { location, image } = this.props;
    return (
      <div
        className={`backgroundElement ${
          location ? "backgroundElement-" + location : ""
        }`}
        style={{ backgroundImage: `url(${image ? image : ""})` }}
      ></div>
    );
  }
}
